<template>
  <div class="container">
    <div class="content">
      <div class="left">
        <div class="left-content">
          <!-- 商品类别 -->
          <div class="item">
            <label class="title">商品类别</label>
            <div class="item-content">
              <el-radio v-model="goodsInfo.type" :label="1">实体商品</el-radio>
              <el-radio v-model="goodsInfo.type" :label="2">虚拟商品</el-radio>
              <p class="tips">如果是VIP会员、充值、录制课程、录制音频视频等，请选择"虚拟商品"类别。（选择后不可更改）</p>
              <p class="tips">虚拟商品暂不支持购买、分销</p>
            </div>
          </div>

          <!-- 基本信息 -->
          <div class="item">
            <label class="title">基本信息</label>
            <div class="item-content">
              <el-form label-position="left" label-width="100px" :model="goodsInfo">
                <!-- 商品名称 -->
                <el-form-item label="商品名称" required>
                  <el-input v-model="goodsInfo.name" 
                    placeholder="最长不超过100字" maxlength="100" show-word-limit></el-input>
                </el-form-item>
                <!-- 商品分类 -->
                <el-form-item label="商品分类" required>
                  <el-cascader v-model="goodsInfo.categoryId" placeholder="请选择商品分类" style="width:100%"
                    :options="sortList"
                    :props="props"
                    clearable></el-cascader>
                </el-form-item>
                <!-- 商品简介 -->
                <el-form-item label="商品简介">
                  <el-input type="textarea" v-model="goodsInfo.description" maxlength="10000" 
                    placeholder="选填，限制10000字"
                    :autosize="{ minRows: 6, maxRows: 10}" show-word-limit></el-input>
                  <p class="tips">将在商品详情图片前显示,建议10000字以内。</p>
                </el-form-item>
                <!-- 商品轮播图 -->
                <el-form-item label="商品轮播图" required>
                  <draggable :list="goodsInfo.thumbnail">
                    <transition-group>
                      <div class="upload-image" 
                        @click="goodsInfo.thumbnail.splice(index, 1)"
                        v-for="(item, index) in goodsInfo.thumbnail"
                        :key="index + 't'">
                        <img :src="item">
                        <div class="image-hover">
                          <span class="el-icon-view"></span>
                          <span class="el-icon-delete"></span>
                        </div>
                      </div>
                    </transition-group> 
                  </draggable>
                  <div class="upload-image" 
                    v-if="goodsInfo.thumbnail.length < 5"
                    @click="uploadIndexImage()">
                    <span class="el-icon-plus add-image"></span>
                  </div>
                  <p class="tips" style="float: left">商品轮播图将以轮播图的形式在页面详情展现，第一张默认为封面图，图片比例1:1，最多上传5张，尺寸750*750，大小在1M以内</p>
                </el-form-item>
                <!-- 商品详情图 -->
                <el-form-item label="商品详情图" required>
                  <draggable :list="goodsInfo.images">
                    <transition-group>
                      <div class="upload-image"
                        @click="goodsInfo.images.splice(index, 1)"
                        v-for="(item, index) in goodsInfo.images"
                        :key="index + 'i'">
                        <img :src="item">
                        <div class="image-hover">
                          <span class="el-icon-view"></span>
                          <span class="el-icon-delete"></span>
                        </div>
                      </div>
                    </transition-group> 
                  </draggable>
                  <div class="upload-image" @click="uploadDescImage()"
                    v-if="goodsInfo.images.length < 25">
                    <span class="el-icon-plus add-image"></span>
                  </div>
                  <p class="tips" style="float: left; width: 100%">上传与商品详情有关图片，最多上传25张，单张不超过1M</p>
                </el-form-item>
                <!-- 商品排序 -->
                <el-form-item label="商品排序">
                  <el-input-number v-model="goodsInfo.weight" label="描述文字"></el-input-number>
                </el-form-item>
                <!-- 支付设置 -->
                <el-form-item label="支付设置" v-if="goodsInfo.type == '1'">
                  <el-radio v-model="goodsInfo.payStatus" :label="1">支持线上支付</el-radio>
                  <el-radio v-model="goodsInfo.payStatus" :label="2">仅线上展示</el-radio>
                </el-form-item>
              </el-form>
            </div>
          </div>

          <!-- 价格库存 -->
          <div class="item">
            <label class="title">价格库存</label>
            <div class="item-content">
              <el-form label-position="left" label-width="100px" :model="goodsInfo">
                <!-- 价格 -->
                <el-form-item label="销售单位" required>
                  <el-input v-model="goodsInfo.productUnit" placeholder="件"></el-input>
                </el-form-item>
                <el-form-item label="商品市场价" required>
                  <el-input-number v-model="goodsInfo.marketPrice" :min="1"></el-input-number>
                </el-form-item>
                <el-form-item label="商品售价" required>
                  <el-input-number v-model="goodsInfo.salesPrice" :min="1"></el-input-number>
                </el-form-item>
                <el-form-item label="商品成本" required>
                  <el-input-number v-model="goodsInfo.costPrice" :min="1"></el-input-number>
                </el-form-item>

                <!-- 库存 -->
                <el-form-item label="商品库存" required>
                  <el-input-number v-model="goodsInfo.productStock" :min="1"></el-input-number>
                </el-form-item>
              </el-form>
            </div>
          </div>

          <!-- 配送 -->
          <div class="item">
            <label class="title">配送</label>
            <div class="item-content">
              <el-form label-position="left" label-width="100px">
                <!-- 物流设置 -->
                <el-form-item label="物流设置">
                  <el-radio v-model="goodsInfo.configs.distributionMode" :label="0">无需物流</el-radio>
                  <el-radio v-model="goodsInfo.configs.distributionMode" :label="1">需要物流</el-radio>
                  <p v-if="goodsInfo.configs.distributionMode == 0" class="tips">选择无需物流时，在购买时无需填写收货地址</p>
                </el-form-item>

                <!-- 快递运费 -->
                <el-form-item label="快递运费" v-if="goodsInfo.configs.distributionMode == 1">
                  <div class="freight">
                    <!-- <el-radio v-model="radio" label="1">统一运费</el-radio> -->
                    <el-input-number v-model="goodsInfo.configs.distributionPrice" 
                      class="item-inline-block"
                      placeholder="最长不超过100字" maxlength="100" show-word-limit></el-input-number>
                    <span class="tips" style="vertical-align: text-bottom;">默认0元，即商品免运费</span>
                  </div>
                  <!-- <div class="freight">
                    <el-radio v-model="radio" label="2">运费模板</el-radio>
                    <el-select v-model="goodsInfo.value1" multiple placeholder="请选择商品分类" style="width:200px">
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                    <span class="tips">刷新</span>
                    <span class="tips">新建</span>
                  </div> -->
                </el-form-item>

                <!-- 填写内容 -->
                <!-- <el-form-item label="选择填写内容">
                  <p class="tips" style="margin-top:0;line-height:36px">可选择提交订单时用户需要填写的字段，选择后用户必须填写</p>
                </el-form-item> -->
              </el-form>
            </div>
          </div>

          <!-- 限购 -->
          <!-- <div class="item">
            <label class="title">限购</label>
            <div class="item-content">
              <el-form label-position="left" label-width="100px" :model="goodsInfo">
                <el-form-item label="限购方式">
                  <el-select v-model="goodsInfo.value1" placeholder="请选择限购方式">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
          </div> -->

          <!-- 上架状态 -->
          <div class="item">
            <label class="title">上架状态</label>
            <div class="item-content">
              <el-radio v-model="goodsInfo.status" :label="0">暂不上架（添加至未上架）</el-radio>
              <el-radio v-model="goodsInfo.status" :label="1">即时上架（添加至已上架）</el-radio>
            </div>
          </div>

          <!-- 确认按钮 -->
          <el-button @click="updateGoods()" type="primary" class="create-button">
            {{$route.query.id ? '保 存' : '创 建'}}
          </el-button>
        </div>
      </div>
      
      <!-- 手机预览框 -->
      <div class="right">
        <div class="mobile">
          <h4 style="color:rgba(0,0,0,0.65);margin-bottom:10px">实时效果预览图</h4>
          <goods-desc :info="goodsInfo"></goods-desc>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imageDialog from '@/websites/cms/js/dialog'
import goodsDesc from '@/websites/cms/components/shop/GoodsDesc'
import draggable from "vuedraggable"
export default {
  data(){
    return {
      radio: "",
      goodsInfo: {
        name: '',
        type: 1,
        weight: 1, // 商品权重
        categoryId: "",
        thumbnail: [],
        images: [],
        description: '',
        status: 0, // 商品状态。-1：删除；0：禁用；1：启用'
        payStatus: 1, // 支付方式1:允许支付 2:仅展示
        marketPrice: 0, // 商品市场价
        salesPrice: 0, // 商品销售价
        costPrice: 0, // 商品成本价
        productStock: 0, // 商品库存
        productUnit: "件", // 库存单位
        configs: { // 商品自定义配置
          distributionMode: 0, // 商品配送方式，0为无需物流，1为需要物流
          distributionPrice: 0, // 商品配送价格
        }
      },
      sortList: [],
      props: { 
        checkStrictly: true,
        multiple: true,
        label: 'name',
        value: 'id'
      },
    }
  },
  created(){
    this.getSortList();
  },
  mounted(){
    if(this.$route.query.id) this.getGoodsDetail(this.$route.query.id);
  },
  methods: {
    // 添加商品
    async updateGoods(){
      let data = JSON.parse(JSON.stringify(this.goodsInfo));
      if(data.name.length < 3) return this.$message.error("商品名称至少要3个字");

      // 将产品的缩略图、详情图、configs转换为字符串
      if(data.thumbnail.length > 0){
        data.thumbnail = JSON.stringify(data.thumbnail);
      }else{
        return this.$message.error("至少要有一张商品轮播图");
      }

      if(data.images.length > 0){
        data.images = JSON.stringify(data.images);
      }else{
        return this.$message.error("至少要有一张商品详情图");
      }

      // 将产品的分类信息保存到configs中，并将分类ID格式化成字符串
      if(this.goodsInfo.categoryId){
        data.configs.category = this.goodsInfo.categoryId;
        data.configs = JSON.stringify(data.configs); 
        let category = "";
        data.categoryId.map(e => category = category + e + ',');
        data.categoryId = category.substr(0, category.length - 1);  
      }else{
        return this.$message.error("商品分类不能为空");
      }

      let res;
      if(this.$route.query.id){
        res = await this.$ZGManager.updateGoods(this.$route.query.id, data);
        if(res.status != 200) return;
        this.$message.success("保存成功");
      }else{
        res = await this.$ZGManager.createGoods(data);
        if(res.status != 200) return;
        this.$message.success("创建成功");
        this.$router.replace({ query: { id: res.data.id }});
      }
    },

    // 获取商品详情
    async getGoodsDetail(gid){
      let res = await this.$ZGManager.getGoodsDesc(gid);
      if(res.status == 200){
        // 将缩略图和轮播图由字符串转为数组
        res.data.images = JSON.parse(res.data.images);
        res.data.thumbnail = JSON.parse(res.data.thumbnail);
        res.data.configs = JSON.parse(res.data.configs);
        res.data.configs.distributionMode = Number(res.data.configs.distributionMode);
        if(res.data.configs.category) res.data.categoryId = res.data.configs.category;
        this.goodsInfo = res.data;
      }
    },

    // 查询分类列表
    async getSortList(){
      let res = await this.$ZGManager.getSortList();
      if(res.status != 200) return;
      let result = [];
      
      // 先循环一遍，将父级分类推入到分类数组，将子级分类推入到result数组
      res.data.map((e) => {
        if(e.parentId == 0) {
          e.children = [];
          this.sortList.push(e);
        }else{
          result.push(e);
        }
      });

      // 再循环sortList父级分类数组，从result数组中找到对应的子类
      this.sortList.map((e, index) => {
        result.map((item) => {
          if(e.id == item.parentId) this.sortList[index].children.push(item);
        });
      });
    },

    // 上传商品轮播图
    uploadIndexImage(){
      imageDialog.show({
        type: 'crooperImage',
        cropWidth: 750,
        cropHeight: 750,
        fixedArray: ['1', '1'],
        imageSize: 1,
        selected: (item) => {this.goodsInfo.thumbnail.push(item.url)}
      });
    },

    // 上传商品详情图
    uploadDescImage(){
      imageDialog.show({
        type: 'crooperImage',
        cropWidth: '360',
        cropHeight: '360',
        isFixed: false,
        imageSize: 1,
        selected: (item) => {this.goodsInfo.images.push(item.url)}
      });
    },
  },
  components: {
    'goods-desc': goodsDesc,
    draggable
  }
}
</script>

<style lang="scss" scoped>
.content{
  background-color: #fff;
  padding: 15px;
  overflow: hidden;
  height: calc(100% - 30px);
}
.left{
  width: calc(100% - 430px);
  height: 100%;
  float: left;
  overflow-y: auto;
  .left-content{
    max-width: 800px;
    .create-button{
      margin-left: 70px;
    }
  }
}
.right{
  width: 375px;
  height: calc(100% - 40px);
  padding: 20px;
  background-color: rgb(248, 249, 250);
  border: 1px solid rgb(240, 240, 240);
  float: right;
  .mobile{
    width: 375px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
.item{
  padding: 20px;
  overflow: hidden;
  .title{
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    line-height: 40px;
  }
  .item-content{
    width: calc(100% - 50px);
    margin-left: 50px;
    margin-top: 20px;
    .tips{
      color: #999;
      font-size: 12px;
      line-height: 24px;
      margin-top: 16px;
    }
    .freight{
      span{
        line-height: 36px; 
        vertical-align: super;
      }
      ::v-deep .el-radio{
        vertical-align: super;
      }
      ::v-deep .el-select{
        vertical-align: super;
        margin-right: 10px;
      }
      ::v-deep .el-input{
        vertical-align: super;
        width: 200px;
      }
    }
    .upload-image{
      float: left;
      width: 100px;
      height: 100px;
      background-color: #e9e9e9;
      cursor: pointer;
      margin-right: 20px;
      position: relative;
      overflow: hidden;
      &:hover{
        .image-hover{
          display: block;
        }
      }
      img{
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .add-image{
        font-size: 30px;
        display: inline-block;
        width: 100%;
        line-height: 100px;
        text-align: center;
        color: #fff;
      }
      .image-hover{
        display: none;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        position: relative;
        z-index: 1;
        span{
          float: left;
          width: 50%;
          line-height: 100px;
          text-align: center;
          color: #fff;
          font-size: 20px;
          font-weight: bold;
        }
      }
      
    }
  }
}
</style>