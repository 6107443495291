<template>
  <div class="goods-content">
    <div class="header">
      <!-- 轮播图 -->
      <van-swipe :autoplay="3000" class="goods-swiper">
        <van-swipe-item v-for="(image, index) in info.thumbnail" :key="index">
          <img v-lazy="image"/>
        </van-swipe-item>
      </van-swipe>

      <!-- 商品标题 -->
      <h3 class="van-hairline--top">{{info.name}}</h3>
    </div>

    <div class="details">
      <p class="goods-desc">{{info.desc}}</p>
      <div class="image">
        <img v-for="(item, index) in info.images" :key="index" :src="item">
      </div>
    </div>
  </div>
</template>

<script>
import {cookie, promisify, isObject} from '@/js/Utils'
import {Swipe, SwipeItem, Lazyload} from 'vant'
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Lazyload)
export default {
  props: ['info'],
  data(){
    return {
      
    }
  },
  created() {
    
  },
  mounted() {
  
  },
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>
.header{
  background-color: #fff;
  .goods-swiper{
    width: 100%;
    height: 7.5rem;
    position: relative;
    img{
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  h3{
    word-break:break-all;
    word-wrap:break-word;
    padding: 0.25rem;
    font-size: 0.28rem;
    line-height: 0.4rem;
  }
}
.details{
  width: 100%;
  background-color: #fff;
  padding: 0.25rem 0;
  margin-top: 0.25rem;
  .goods-desc{
    padding: 0 0.25rem;
  }
  .image{
    width: 100%;
    img{
      max-width: 100%;
    }
  }
}
</style>